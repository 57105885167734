function toggleValidityMessage(inputElement, valid, message = "Error") {
    if (valid) {
        inputElement.setCustomValidity("");
    } else {
        inputElement.setCustomValidity(message);
    }
}

function showErrors(inputElement) {
    if (inputElement.classList.contains("user-input")) {
        let errorElem = document.getElementById(
                inputElement.getAttribute("aria-errormessage")
            );
        const isValid = inputElement.validationMessage === "";
        if (inputElement.value.trim() === "" && !isValid) {
            errorElem.textContent = inputElement.dataset.errormessage;
        } else {
            errorElem.textContent = inputElement.validationMessage;
        }
        inputElement.setAttribute(
            "aria-invalid",
            !isValid
        );
    }
}

function userInput(inputElement) {
    inputElement.classList.add("user-input");
    showErrors(inputElement);
}

export {
    userInput
};
