// import Vue from "vue";
// import { createPinia, PiniaVuePlugin } from "pinia";
import BookingWidget from './components/BookingWidget.vue';

const siteLang = window.searchWidget.siteLang;

const datepickerOptions = {
  sundayFirst: true,
  daysShort: siteLang == "FR" ?
      ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'] :
      ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  monthNames: siteLang == "FR" ?
      ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'] :
      ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  colors: {
    selected: '#57565b',
    inRange: '#4c4c4c',
    selectedText: '#fff',
    text: '#565a5c',
    inRangeBorder: '#33dacd',
    disabled: '#fff',
    hoveredInRange: '#606060',
  },
};

window.Vue.use(AirbnbStyleDatepicker, datepickerOptions);
window.Vue.use(Pinia.PiniaVuePlugin);
window.Vue.use(Pinia.createPinia());

window.Vue.component('booking-widget', BookingWidget);

window.BookingWidgetComponet = {
  component: {
    'booking-widget': BookingWidget,
  }
};
