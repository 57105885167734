<script>
import {useMainStore} from "@/stores/mainStore";
import TooManyModal from "@/components/parts/TooManyModal.vue";

const currentDate = new Date();
const maxDaysSelectable = window.searchWidget.datepickerConfig.maxDays ?? 30;
export default {
  props: {
    isDropdownMode: Boolean,
    night: String,
    nights: String,
    flexibleDatesText: String,
    toConfirmMore: String,
    pleaseCall: String,
    omniAssist: String,
  },
  components: {
    TooManyModal
  },
  data() {
    return {
      pickerId: "dates-picker",
      arrivalDateString: "",
      departureDateString: "",
      minInputDate: new Date(),
      minDateForArrivalString: "",
      errorMessage: "",
      forceUpdateOfPicker: true,
    };
  },
  methods: {
    createTimezoneCorrectDate(date) {
      const dateParts = date.split("-");
      return new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
    },
    formatDateForDateInput(date) {
      return date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, "0") + "-" + (date.getDate()).toString().padStart(2, "0"); //gets date in format yyyy-mm-dd
    },
    formatDateForOutput(dateString, showYear = false) {
      if (dateString !== "") {
        const date = this.createTimezoneCorrectDate(dateString);
        const options = {month: "short", day: "numeric"};
        if (showYear) {
          options.year = "numeric"
        }
        return new Intl.DateTimeFormat(window.searchWidget.siteLang == "FR" ? "fr-CA" : "en-US", options).format(date);
      }
    },
    arrivalDateSelected(date) {
      if (date) {
        const checkDate = this.createTimezoneCorrectDate(date);
        if (this.calculateDaysBetween(currentDate, checkDate) >= 0) {
          this.arrivalDateString = date;
        } else {
          this.arrivalDateString = this.formatDateForDateInput(currentDate);
        }
        if (this.calculateDaysBetween(this.arrivalDate, this.departureDate) <= 0) {
          this.setDepartureDateAheadOfArrivalDate();
        }
      }
    },
    departureDateSelected(date) {
      if (date) {
        const checkDate = this.createTimezoneCorrectDate(date);
        if (this.calculateDaysBetween(this.arrivalDate, checkDate) > 0) {
          this.departureDateString = date;
        } else {
          this.setDepartureDateAheadOfArrivalDate();
        }
        this.forceUpdateOfPicker = false;
        window.Vue.nextTick(() => {
          this.forceUpdateOfPicker = true;
        });
      }
    },
    setDepartureDateAheadOfArrivalDate() {
      const newDate = new Date(this.arrivalDate);
      newDate.setDate(newDate.getDate() + 1);
      this.departureDateString = this.formatDateForDateInput(newDate);
    },
    calculateDaysBetween(startDate, endDate) {
      const millisecondsPerDay = 24 * 60 * 60 * 1000;
      return (Date.UTC(endDate.getYear(), endDate.getMonth(), endDate.getDate()) - (Date.UTC(startDate.getYear(), startDate.getMonth(), startDate.getDate()))) / millisecondsPerDay;
    },
    tooManyDaysSelected() {
      if (this.daysBetweenDates > maxDaysSelectable) {
        this.errorMessage = maxDaysSelectable + " nights";

        const newDepartureDate = new Date(this.arrivalDate);
        newDepartureDate.setDate(
            newDepartureDate.getDate() + maxDaysSelectable
        );
        this.departureDateString = this.formatDateForDateInput(newDepartureDate);
        this.forceUpdateOfPicker = false;
        window.Vue.nextTick(() => {
          this.forceUpdateOfPicker = true;
        });
        this.mainStore.openPicker(document.getElementById(this.pickerId));
      }
    }
  },
  computed: {
    ...Pinia.mapStores(useMainStore),
    pickerOpenBool() {
      return (this.mainStore.pickerVisibleId === this.pickerId);
    },
    daysBetweenDates() {
      return this.calculateDaysBetween(
          this.arrivalDate,
          this.departureDate
      );
    },
    arrivalDate() {
      return this.createTimezoneCorrectDate(this.arrivalDateString);
    },
    departureDate() {
      return this.createTimezoneCorrectDate(this.departureDateString);
    },
    nightsSelectString() {
      return `(${this.daysBetweenDates} ${this.daysBetweenDates > 1 ? this.nights : this.night})`;
    },
    arrivalDateFormattedString() {
      return this.formatDateForOutput(this.arrivalDateString, true);
    },
    departureDateFormattedString() {
      return this.formatDateForOutput(this.departureDateString, true);
    },
    fullFormattedDateString() {
      return `${this.formatDateForOutput(this.arrivalDateString)} - ${this.formatDateForOutput(this.departureDateString)}`;
    },
    minDateForDepartureString() {
      const minDate = new Date(this.arrivalDate);
      minDate.setDate(minDate.getDate() + 1);
      return this.formatDateForDateInput(minDate);
    },
    maxDateForDepartureString() {
      const maxDate = new Date(this.arrivalDate);
      maxDate.setDate(maxDate.getDate() + maxDaysSelectable);
      return this.formatDateForDateInput(maxDate);
    }
  },
  mounted() {
    this.mainStore.flexibleDates = this.mainStore.getFormData(this.mainStore.fieldNames.flexisearch, 0);

    this.arrivalDateSelected(this.mainStore.getFormData(
        this.mainStore.fieldNames.arrival,
        this.formatDateForDateInput(currentDate)
    ));

    let checkNightsInt = Number.parseInt(this.mainStore.getFormData(this.mainStore.fieldNames.nights));
    const nights = Number.isInteger(checkNightsInt) ? checkNightsInt : 1;

    const dayPlusOneDate = new Date(this.arrivalDate);
    dayPlusOneDate.setDate(dayPlusOneDate.getDate() + nights);
    this.departureDateSelected(this.mainStore.getFormData(
        this.mainStore.fieldNames.departure,
        this.formatDateForDateInput(dayPlusOneDate)
    ));

    this.minDateForArrivalString = this.formatDateForDateInput(this.minInputDate);
    this.tooManyDaysSelected();
    this.$watch(() => this.daysBetweenDates, () => {
      this.tooManyDaysSelected();
    });

    //Emit external event
    this.$watch(
        () => this.fullFormattedDateString,
        (dateString) => {
          if (dateString !== undefined &&
              this.arrivalDateString !== undefined &&
              this.departureDateString !== undefined) {
            this.$emit("date-string", dateString);
            const newDateProperties = {
              dateString: dateString,
              arrivalDateString: this.arrivalDateString,
              departureDateString: this.departureDateString,
              nights: this.daysBetweenDates
            };
            window.bookingWidget.data.dates = newDateProperties;
            window.dispatchEvent(new CustomEvent("datesupdated", {
              detail: newDateProperties
            }));
            window.dispatchEvent(new CustomEvent("nightsupdated", {
              detail: {
                nights: this.daysBetweenDates
              }
            }));
          }
        },
        {immediate: true}
    );

      this.$watch(
          () => this.isDropdownMode,
          (isDropdownMode) => {
              if(isDropdownMode) {
                  this.$refs.datesInputEl.append(...this.$refs.datesInputTitleEl.childNodes);
              } else {
                  this.$refs.datesInputTitleEl.append(...this.$refs.datesInputEl.childNodes);
              }
          },
          {immediate: true}
      );

    //Listen to external events
    window.addEventListener("nightsupdated", e => {
      const numberOfNightsInt = Number.parseInt(e.detail.nights);
      if (Number.isInteger(numberOfNightsInt) && numberOfNightsInt >= 1) {
        const newDepartureDate = new Date(this.arrivalDate);
        newDepartureDate.setDate(
            newDepartureDate.getDate() + numberOfNightsInt
        );
        this.departureDateString = this.formatDateForDateInput(newDepartureDate);
      }
    });
    this.$emit("date-errors-callback", () => {
      return this.calculateDaysBetween(currentDate, this.arrivalDate) >= 0
          && this.calculateDaysBetween(this.arrivalDate, this.departureDate) > 0
          && this.daysBetweenDates <= maxDaysSelectable;
    });

  },
}
</script>
<template>
  <li
      ref="datePickerElem"
      :id="pickerId"
      class="picker picker--dates"
  >
    <div class="picker__title picker__title--date-icon">
      <button
          id="date-picker-button"
          class="picker__title__button"
          type="button"
          aria-expanded="false"
          :aria-controls="pickerId+'-dropdown'"
          @click="mainStore.togglePicker($refs.datePickerElem);"
          :data-picker-id="pickerId">
        <span v-if="isDropdownMode" class="picker__title__text">
          {{ fullFormattedDateString }}
        </span>
        <span v-if="isDropdownMode" class="sr-only"> dates selected</span>
        <span v-else class="sr-only">
          Open date picker
        </span>
      </button>
      <div v-show="!isDropdownMode"
           ref="datesInputTitleEl"
           id="dates-input-title"
           class="dates-input"
           :class="{'dates-input--disabled': !pickerOpenBool && !isDropdownMode}"
      ></div>
    </div>
    <fieldset :id="pickerId+'-dropdown'" class="picker__dropdown picker__dropdown--dates-picker">
      <legend class="sr-only">Select Dates</legend>
      <button class="picker__close-button close-dropdown" type="button" aria-label="close date picker" @click="mainStore.closePickerAndFocusPickerButton"></button>
      <TooManyModal
          :id="'error-modal-date'"
          :hidden="errorMessage === ''"
          :error-string="errorMessage"
          :title="'nights'"
          :toConfirmMore="toConfirmMore"
          :pleaseCall="pleaseCall"
          :omniAssist="omniAssist"
          :top-offset="'0px'"
          @closed="errorMessage = ''"
      ></TooManyModal>
      <div ref="datesInputEl" class="dates-input">
        <div class="date-input"
             :data-date-mask="arrivalDateFormattedString">
          <label for="booking-widget-arrival-date" class="sr-only">Select arrival date</label>
          <input
              id="booking-widget-arrival-date"
              class="date-input__input"
              type="date"
              :name="mainStore.fieldNames.arrival"
              :value="arrivalDateString"
              :min="minDateForArrivalString"
              :readonly="!pickerOpenBool && !isDropdownMode"
              :tabIndex="!pickerOpenBool && !isDropdownMode? -1: 0"
              @blur="arrivalDateSelected($event.target.value);"
          />
        </div>
        <span>&nbsp;-&nbsp;</span>
        <div class="date-input"
             :data-date-mask="departureDateFormattedString">
          <label for="booking-widget-departure-date" class="sr-only">Select departure date</label>
          <input
              id="booking-widget-departure-date"
              class="date-input__input"
              type="date"
              :name="mainStore.fieldNames.departure"
              :value="departureDateString"
              :min="minDateForDepartureString"
              :max="maxDateForDepartureString"
              :readonly="!pickerOpenBool && !isDropdownMode"
              :tabIndex="!pickerOpenBool && !isDropdownMode? -1: 0"
              @blur="departureDateSelected($event.target.value);"
          />
        </div>
        <span class="dates-input__nights">{{ nightsSelectString }}</span>
      </div>
      <span class="flexible-dates" v-if="mainStore.formData.token === undefined">
                <input
                    id="flexible-dates-input"
                    type="checkbox"
                    :name="mainStore.fieldNames.flexisearch"
                    v-model="mainStore.flexibleDates"
                    true-value="1"
                    value="1"
                />
                <label for="flexible-dates-input">
                    <span class="custom-check"></span>
                    {{flexibleDatesText}}
                </label>
            </span>
      <div v-if="mainStore.isPickerOpen($refs.datePickerElem)"
          id="datepicker-trigger"
          class="booking-widget-datepicker datepicker-trigger"
      >
        <AirbnbStyleDatepicker
            :trigger-element-id="'datepicker-trigger'"
            :trigger="forceUpdateOfPicker"
            :inline="forceUpdateOfPicker"
            :mode="'range'"
            :date-one="arrivalDateString"
            :date-two="departureDateString"
            :min-date="minDateForArrivalString"
            :months-to-show="1"
            :close-after-select="true"
            :show-shortcuts-menu-trigger="false"
            :show-action-buttons="false"
            @date-one-selected="(date) => arrivalDateSelected(date)"
            @date-two-selected="(date) => departureDateSelected(date)"
        >
        </AirbnbStyleDatepicker>
      </div>
    </fieldset>
  </li>
</template>
