<script>
import HotelsPicker from "./pickers/HotelsPicker.vue";
import DatePicker from "./pickers/DatePicker.vue";
import GuestsPicker from "./pickers/GuestsPicker.vue";
import SpecialRatesPicker from "./pickers/SpecialRatesPicker.vue";
import {useMainStore} from "@/stores/mainStore";
import {submitBookingWidget} from "/public/js/gtm-tracking.js";

window.bookingWidget = window.bookingWidget ?? {};
window.bookingWidget.data = {};
let dialog = null;
const mediaQueryMaxSizeInt = 1100;
const mediaQuery = window.matchMedia(`(min-width: ${mediaQueryMaxSizeInt}px)`);

/**
 * Update sidebar button text
 */
document.addEventListener("DOMContentLoaded", () => {
  const widgetDateButtonsList = document.querySelectorAll(
      "[data-widget-selected-dates]"
  );
  widgetDateButtonsList.forEach(
      (e) => (e.textContent = window.bookingWidget.data.dates.dateString)
  );
  window.addEventListener("datesupdated", (event) => {
    widgetDateButtonsList.forEach(
        (e) => (e.textContent = event.detail.dateString)
    );
  });
  const widgetGuestsButtonsList = document.querySelectorAll(
      "[data-widget-selected-guests]"
  );
  widgetGuestsButtonsList.forEach(
      (e) => (e.textContent = window.bookingWidget.data.guests)
  );
  window.addEventListener("guestsupdated", (event) => {
    widgetGuestsButtonsList.forEach(
        (e) => (e.textContent = event.detail.guests)
    );
  });
});

export default {
  components: {
    HotelsPicker, DatePicker, GuestsPicker, SpecialRatesPicker
  },
  props: {
    mode: {
      default: "dropdown-to-modal",
      validator(value) {
        return [
          "modal",
          "dropdown",
          "in-place",
          "in-place-reveal",
          "dropdown-to-modal",
          "dropdown-to-in-place",
        ].includes(value);
      },
    },
      initialAction: {
          type: String,
          required: true
      },
      interconnectingAction: {
          type: String,
          required: true
      },
    csrfToken: {
      required: true,
    },
    buttonText: {
      type: String,
      default: "Search",
    },
    freeNightsLink: {
      type: String,
      default: "Search",
    },
    whereAreYouGoing: {type: String, default: "Where are you going?"},
    night: {type: String, default: "night"},
    nights: {type: String, default: "nights"},
    flexibleDatesText: {
      type: String,
      default: "I have flexible dates",
    },
    interconnectingText: { type: String,  default: "I want connecting rooms"},
    guaranteedConnectingRooms: {type: String, default: "Guaranteed connecting rooms"},
    interconnectingExtraOne: {type: String, default: "Two independent rooms that are connected by a  door."},
    interconnectingExtraTwo: {type: String, default: "Entails a small charge per night."},
    room: {type: String, default: "room"},
    rooms: {type: String, default: "rooms"},
    adult: {type: String, default: "adult"},
    adults: {type: String, default: "adults"},
    childDefinition: String,
    child: {type: String, default: "child"},
    children: {type: String, default: "children"},
    guest: {type: String, default: "guest"},
    guests: {type: String, default: "guests"},
    howManyRooms: {type: String, default: "HOW MANY ROOMS"},
    guestChildrenAges: {type: String, default: "Children's Ages"},
    theAge: {type: String, default: "AGE"},
    specialRates: {type: String, default: "special rates"},
    allRates: {type: String, default: "all rates"},
    seniorDiscount: {type: String, default: "Senior Discount (ages 55+)"},
    governmentMilitary: {type: String, default: "Government/Military"},
    promoCode: {type: String, default: "promo code"},
    corporateCode: {type: String, default: "corporate code"},
    groupCode: {type: String, default: "group code"},
    selectGuestMember: {type: String, default: "select guest® member"},
    redeemFreeNights: {type: String},
    useFreeNights: {type: String, default: "use free nights"},
    travelAgentNumber: {type: String, default: "travel agent number"},
    toConfirmMore: {type: String, default: "To confirm more than"},
    pleaseCall: {type: String, default: "please call"},
    omniAssist: {
      type: String,
      default:
          'and an Omni Hotels representative will gladly assist you": "et un représentant d\'Omni Hotels se fera un plaisir de vous aider',
    },
    associateBookingsMaxRoomsString: {type: String, default: ""},
    associateBookingsMaxAdultsString: {type: String, default: ""},
    currentHotelIdString: {type: String, default: "0"},
    associateBookingEmployeeHomeHotelIdsString: {type: String, default: ""},
    apiLanguageCookieRoute: {type: String, default: ""},
    facadeButtonText: {
      type: String,
      default: null,
    },
    hasSpecialRates: {
      type: Boolean,
      default: true,
    },
    isModifyingBooking: {
      type: Boolean,
      default: false
    },
    text: {
      type: Object,
      default: {}
    },
    hideSelectGuest: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
        excludedHotelIdsForConnectingRoomsOption: [110002, 110037, 110036,110035,110034,110001],
        showAdditionalTextInterconnecting: false,
        ibeCore: this.$parent,
        facadeButtonTextString:
          this.facadeButtonText === null
              ? this.buttonText
              : this.facadeButtonText,
      isInPlaceReveal: this.mode === "in-place-reveal",
      isOpenInPlace: false,
      dateString: "",
      guestString: "",
      dropdownMode: !["modal", "in-place", "in-place-reveal"].includes(
          this.mode
      ),
      isModal: this.mode === "modal",
      facadeButtonClickFunction: () => {
      },
      facadeOpenFunction: () => {
      },
      formSubmitCallbackArray: [],
    };
  },
  methods: {

      toggleTextForInterconnectingCheckbox() {
          this.showAdditionalTextInterconnecting = !this.showAdditionalTextInterconnecting;
      },



    toggleInPlaceReveal() {
      this.isOpenInPlace = !this.isOpenInPlace;
    },

    openPlaceReveal() {
      this.isOpenInPlace = true;
    },

    showHideModal(show) {
      this.isModal = show;
      if (!show) {
        this.closeDialog();
      }
    },

    openDialog() {
      if (dialog && this.isModal) {
        document.firstElementChild.classList.add("disable-scroll");
        dialog.modal('show');
      }
    },

    closeDialog() {
      if (dialog) {
        document.firstElementChild.classList.remove("disable-scroll");
        dialog.modal('hide');
      }
    },

    toggleBookingWidget() {
      this.facadeButtonClickFunction();
    },

    formSubmitted(formSubmitEvent) {
      this.formSubmitCallbackArray.forEach((checkIfValidFunc) => {
        if (checkIfValidFunc() === false) {
          formSubmitEvent.preventDefault();
          this.facadeOpenFunction();
        }
      });

      this.apiSetLanguageCookie(formSubmitEvent);
      submitBookingWidget();
    },

    async apiSetLanguageCookie(formSubmitEvent) {
      formSubmitEvent.preventDefault();
      let tempHotelId = parseInt(this.currentHotelIdString);
      if (tempHotelId != this.mainStore.selectedHotelId) {
        await fetch(this.apiLanguageCookieRoute, {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
          }
        }).then(response => response.json()).then((response) => {
          if (response.success) {
            this.$refs.formElement.submit();
          }
        }).catch((error) => {
          console.log(error)
        });
      } else {
        this.$refs.formElement.submit();
      }
    },

    formSubmitClick(event) {
      event.stopPropagation();
      if (this.$refs.formElement.checkValidity() === true) {
        return;
      }
      const invalidElementsArray =
          this.$refs.formElement.querySelectorAll(
              ":invalid:not(form):not(fieldset)"
          );

      const pickerElArr = Array.from(this.$refs.formElement.getElementsByClassName("picker"));
      for (const invalidElement of invalidElementsArray) {
        const invalidPickerElem = pickerElArr.find( (pickerElement) => {
            return pickerElement.contains(invalidElement);
          });

        if(invalidPickerElem){
          this.mainStore.openPicker(invalidPickerElem);
          this.facadeOpenFunction();
          if(invalidElement.dataset.focusOnInvalidId){
            document.getElementById(invalidElement.dataset.focusOnInvalidId).focus();
          }else{
            invalidElement.focus();
          }
          break;
        }
      }
    },
  },
  computed: {
    isConnectingRoomsConditionMet() {
      return this.ibeCore.selectedRooms == 2 && !this.associateBookingsMaxRoomsString &&
          (this.ibeCore.selectedHotel === undefined || !this.excludedHotelIdsForConnectingRoomsOption.includes(this.ibeCore.selectedHotel));
    },
    showConnectingDiv() {
      return this.isConnectingRoomsConditionMet;
    },
    formAction() {
        if (this.mainStore.interconnectingRoomsChecked && this.isConnectingRoomsConditionMet) {
          return this.interconnectingAction;
        } else {
          return this.initialAction;
        }
      },
    ...Pinia.mapStores(useMainStore),
  },
  beforeCreate() {
    window.bookingWidget.ibeCore = this.$parent;

  },
  created() {
    if (window.bookingWidget.config.fieldNames) {
      this.mainStore.setFieldNames(
          window.bookingWidget.config.fieldNames
      );
    }
    this.mainStore.addFormData(window.p3Hotels.request);
    this.mainStore.isModifyingBooking = this.isModifyingBooking;
    this.mainStore.hideSelectGuest = this.hideSelectGuest;
    this.mainStore.text = this.text;
  },
  mounted() {
      this.mainStore.interconnectingRoomsChecked = this.mainStore.getFormData('interconnecting_rooms_checkbox');
    this.facadeButtonClickFunction = this.isInPlaceReveal
        ? this.toggleInPlaceReveal
        : this.openDialog;
    this.facadeOpenFunction = this.isInPlaceReveal
        ? this.openPlaceReveal
        : this.openDialog;

    if (this.mode === "dropdown-to-in-place") {
      this.dropdownMode = mediaQuery.matches;

      try {
        mediaQuery.addEventListener('change', (mediaQuery) => {
          this.dropdownMode = mediaQuery.matches;
        });
      } catch (e1) {
        try {
          // Old Safari
          mediaQuery.addListener((mediaQuery) => {
            this.dropdownMode = mediaQuery.matches;
          });
        } catch (e2) {
          console.error(e2);
        }
      }
    }
    if (this.mode === "dropdown-to-modal") {
      this.dropdownMode = mediaQuery.matches;
      this.showHideModal(!mediaQuery.matches);
      try {
        mediaQuery.addEventListener("change", (mediaQuery) => {
            this.dropdownMode = mediaQuery.matches;
            this.showHideModal(!mediaQuery.matches);
        });
      } catch (e1) {
        try {
          // Old Safari
          mediaQuery.addListener((mediaQuery) => {
              this.dropdownMode = mediaQuery.matches;
              this.showHideModal(!mediaQuery.matches);
          });
        } catch (e2) {
          console.error(e2);
        }
      }
    }

    if (this.mode.includes("modal")) {
      dialog = $('#booking-widget-dialog');
    }

    this.$refs.formElement.addEventListener("submit", this.formSubmitted);
    this.$refs.formElement.addEventListener("bookingwidgettoggle", () =>
        this.toggleBookingWidget()
    );
    this.$refs.formElement.classList.remove("omni-booking-widget-loading-facade");

    window.addEventListener("click", (e) =>
        this.mainStore.closeAllPickersIfClickedOutside(e.target)
    );

    if (this.$refs.dialogElement) {
      this.$refs.dialogElement.addEventListener("keydown", (e) => {
        if (e.key === "Escape"
            && !this.mainStore.pickerVisibleElem) {
          $(this.$refs.dialogElement).modal('hide');
        }
      });
    }

    this.$refs.formElement.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        this.mainStore.closePickerIfEscPressed(this.mainStore.pickerVisibleElem, e.target);
      }
    });

      this.$watch(
          () => this.isModal,
          (isModal) => {
              if (isModal) {
                  this.$refs.dialogContentEl.appendChild(this.$refs.mainFormEl);
              } else {
                  this.$refs.dropdownListEl.appendChild(this.$refs.mainFormEl);
              }
          },
          {immediate: true}
      );

    window.dispatchEvent(new Event("bookingWidgetReady"));
  },
};
</script>

<template>
  <form
      :action="formAction"
      ref="formElement"
  >
    <!--Facade for activating modal & in-place reveal -->
    <div
        v-if="isModal || isInPlaceReveal"
        class="omni-booking-widget__facade"
    >
         <button
             type="button"
             class="omni-booking-widget__form__main"
             @click="facadeButtonClickFunction"
             :aria-haspopup="isModal ? 'true' : 'false'"
             :aria-controls="isModal ? 'booking-widget-dialog' : 'booking-widget-main-form'"
             :aria-label="'Open search widget'"
         >
        <span
            id="booking-widget-date-button"
            class="picker__title picker__title--date-icon"
        >
          <span class="picker__title__text">
              {{ dateString }}
          </span>
        </span>
             <span id="booking-widget-guest-button" class="picker__title">
          <span class="picker__title__text">
              {{ guestString }}
          </span>
        </span>
         </button>
         <button
             id="booking-widget-search-button"
             type="submit"
             class="btn omni-booking-widget__btn btn-cta"
             :class="isModal ? 'btn-primary' : 'btn-secondary'"
             @click="formSubmitClick($event)"
             :aria-label=" facadeButtonTextString === '' ? 'Search': false"
         >
             {{ facadeButtonTextString }}
         </button>
        <input type="hidden" name="interconnecting_rooms_checkbox" v-bind:value="mainStore.interconnectingRoomsChecked?1:0"/>
        <div v-if="showConnectingDiv" class="interconnecting-checkbox-text interconnecting-checkbox-text-outside interconnecting-checkbox-text-facade mt-3">
            <div class="interconnecting-texts-wrapper">
                <label class="d-flex align-items-start mb-0" for="interconnectingRoomsInputOutside">
                    <div class="form-check">
                        <input v-model="mainStore.interconnectingRoomsChecked"  class="checkbox-input__input form-control" type="checkbox" value="1" name="interconnecting_rooms_checkbox" id="interconnectingRoomsInputOutsideMobile" >
                        <span class="custom-check"></span>

                    </div>
                    <div class="interconnecting-texts">
                        <span> {{ interconnectingText }}</span>
                        <span> Guaranteed connecting rooms</span>
                    </div>

                </label>
                <button type="button" tabindex="0"
                   class="destination-charge__popover-btn"
                   aria-controls="connecting-room-text-popup-outside"
                   :aria-expanded="showAdditionalTextInterconnecting.toString()"
                   @click="toggleTextForInterconnectingCheckbox">
                    <img  :src="'/images/icons/icon-info-connecting-rooms.svg'" alt="">
                </button>
            </div>
            <div id="connecting-room-text-popup-outside" class="interconnecting-text-info-wrapper mt-2 mb-2" v-if="showAdditionalTextInterconnecting">
                <button type="button" @click.stop="toggleTextForInterconnectingCheckbox">
                  <img  :src="'/images/icons/x-connecting.svg'" alt="close">
                </button>
                <div class="interconnecting-text-info ml-3 ">
                    <span class="mb-2">Two independent rooms that are connected by a  door.</span>
                    <span>Entails a small charge per night.</span>
                </div>
            </div>

        </div>

    </div>
    <!-- Dialog holder -->
    <div
        id="booking-widget-dialog"
        class="modal dialog-container"
        ref="dialogElement"
        aria-hidden="true"
        tabindex="-1"
        role="dialog"
        aria-modal="true"
        data-keyboard="false"
        aria-labelledby="booking-widget-dialog-heading"
    >
      <div
          class="dialog-overlay"
          @click="closeDialog"
      ></div>
      <div class="dialog-content modal-dialog">
        <button
            type="button"
            class="dialog-close"
            aria-label="Close dialog"
            @click="closeDialog"
        >
          &times;
        </button>
        <h1 class="sr-only" id="booking-widget-dialog-heading">Search for availability</h1>
        <div
            ref="dialogContentEl"
            id="dialog-content"
            class="omni-booking-widget omni-booking-widget--modal modal-content"
        ></div>
      </div>
    </div>
    <!-- Main form -->
    <div
        ref="dropdownListEl"
        id="bookng-widget-main-form"
        class="omni-booking-widget__form"
        :hidden="isInPlaceReveal && !isOpenInPlace"
    >
      <section ref="mainFormEl"
               class="omni-booking-widget__form__main"
               aria-label="booking details">
        <input v-if="mainStore.formData.bookingRef !== undefined" type="hidden" name="bookingRef"
               :value="mainStore.formData.bookingRef"/>
          <div>
              <ul class="omni-booking-widget__pickers"
              :class='{ "omni-booking-widget__pickers--has-special-rates": hasSpecialRates }'
              >
                  <HotelsPicker
                      :isDropdownMode="dropdownMode"
                      @hotel-errors-callback="(callback) => (formSubmitCallbackArray[0] = callback)"
                      :whereAreYouGoing="whereAreYouGoing"
                      :associateBookingEmployeeHomeHotelIdsArray="associateBookingEmployeeHomeHotelIdsString.split(',')"
                  ></HotelsPicker>
                  <DatePicker
                      @date-string="(d) => (dateString = d)"
                      :night="night"
                      :nights="nights"
                      :flexibleDatesText="this.flexibleDatesText"
                      :toConfirmMore="toConfirmMore"
                      :pleaseCall="pleaseCall"
                      :omniAssist="omniAssist"
                      :isDropdownMode="dropdownMode"
                      @date-errors-callback="(callback) => (formSubmitCallbackArray[1] = callback)"
                  ></DatePicker>
                  <GuestsPicker
                      @guest-string="(g) => (guestString = g)"
                      :room="room"
                      :rooms="rooms"
                      :excludedHotelIdsForConnectingRoomsOption="excludedHotelIdsForConnectingRoomsOption"
                      :interconnectingText="interconnectingText"
                      :guaranteedConnectingRooms="guaranteedConnectingRooms"
                      :interconnectingExtraOne="interconnectingExtraOne"
                      :interconnectingExtraTwo="interconnectingExtraTwo"
                      :adult="adult"
                      :adults="adults"
                      :childDefinition="childDefinition"
                      :child="child"
                      :children="children"
                      :guest="guest"
                      :guests="guests"
                      :howManyRooms="howManyRooms"
                      :guestChildrenAges="guestChildrenAges"
                      :theAge="theAge"
                      :toConfirmMore="toConfirmMore"
                      :pleaseCall="pleaseCall"
                      :omniAssist="omniAssist"
                      :associateBookingsMaxRoomsString="associateBookingsMaxRoomsString"
                      :associateBookingsMaxAdultsString="associateBookingsMaxAdultsString"
                      :isDropdownMode="dropdownMode"
                      @date-errors-callback="(callback) => (formSubmitCallbackArray[2] = callback)"
                  ></GuestsPicker>
                  <SpecialRatesPicker
                      v-show="hasSpecialRates"
                      :freeNightsLink="freeNightsLink"
                      :specialRates="specialRates"
                      :allRates="allRates"
                      :seniorDiscount="seniorDiscount"
                      :governmentMilitary="governmentMilitary"
                      :promoCode="promoCode"
                      :corporateCode="corporateCode"
                      :groupCode="groupCode"
                      :selectGuestMember="selectGuestMember"
                      :redeemFreeNights="redeemFreeNights"
                      :useFreeNights="useFreeNights"
                      :travelAgentNumber="travelAgentNumber"
                  ></SpecialRatesPicker>
              </ul>
              <div v-if="showConnectingDiv" class="interconnecting-checkbox-text interconnecting-checkbox-text-outside mt-3">
                <div class="interconnecting-texts-wrapper">
                    <label class="d-flex align-items-start mb-0" for="interconnectingRoomsInputOutside">
                        <div class="form-check">
                            <input v-model="mainStore.interconnectingRoomsChecked"  class="checkbox-input__input form-control" type="checkbox" value="1" name="interconnecting_rooms_checkbox" id="interconnectingRoomsInputOutside" >
                            <span class="custom-check"></span>

                        </div>
                        <div class="interconnecting-texts">
                            <span>{{ interconnectingText }}</span>
                            <span>{{ guaranteedConnectingRooms }}</span>
                        </div>

                    </label>
                    <button type="button" tabindex="0"
                       class="destination-charge__popover-btn"
                       aria-controls="connecting-room-text-popup-outside-desktop"
                       :aria-expanded="showAdditionalTextInterconnecting.toString()"
                       @click="toggleTextForInterconnectingCheckbox">
                        <img  :src="'/images/icons/icon-info-connecting-rooms.svg'" alt="close">
                    </button>
                </div>
                  <div id="connecting-room-text-popup-outside-desktop" class="interconnecting-text-info-wrapper mt-2 mb-2" v-if="showAdditionalTextInterconnecting">
                     <button type="button" @click.stop="toggleTextForInterconnectingCheckbox">
                       <img  :src="'/images/icons/x-connecting.svg'" alt="close">
                     </button>
                      <div class="interconnecting-text-info ml-3 ">
                          <span class="mb-2">{{ interconnectingExtraOne }}</span>
                          <span>{{ interconnectingExtraTwo }}</span>
                      </div>
                  </div>

              </div>

          </div>
        <button
            type="submit"
            class="btn omni-booking-widget__btn btn-primary btn-cta loading-spinner"
            @click="formSubmitClick($event)"
            :aria-label="buttonText === '' ? 'Search': false"
        >
          {{ buttonText }}
        </button>
      </section>
    </div>
  </form>
</template>
