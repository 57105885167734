<script>
export default {
    props: {
        childDefinition: String
    },
  mounted(){
    document.querySelectorAll(`[aria-controls='${this.$refs.textElem.id}']`)
        .forEach((e) => e.addEventListener("click",
            () => {
              this.$refs.textElem.hidden = !this.$refs.textElem.hidden;
              e.setAttribute("aria-expanded", !this.$refs.textElem.hidden);
            }
        ));
  }
}
</script>
<template>
  <div ref="textElem" class="show-widget__details" hidden>
    <p class="show-widget__text">
      {{childDefinition}}
    </p>
  </div>
</template>
