<script>
import A11yDialog from "a11y-dialog";

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    errorString: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    topOffset: {
      type: String,
      required: true,
    },
    toConfirmMore: String,
    pleaseCall: String,
    omniAssist: String
  },
  data() {
    return {
      textElem: "",
      dialog: null
    }
  },
  methods: {
    closeDialog() {
      if (this.dialog) {
        this.dialog.hide();
        this.$emit("closed");
      }
    }
  },
  mounted() {
    this.dialog = new A11yDialog(this.$refs.dialogElem);
    this.$watch(() => this.errorString,
        () => {
          if (this.errorString !== "") {
            this.dialog.show();
          }
        });
  }
}
</script>
<template :errorString :title>
  <div
      :id="id"
      class="too-many-modal"
      aria-live="polite"
      role="dialog"
      aria-modal="true"
      aria-hidden="true"
      ref="dialogElem"
      :aria-labelledby="`${id}-heading`"
  >
    <div
        class="too-many-modal__backdrop"
        data-a11y-dialog-hide
        @click="closeDialog()"
    ></div>
    <div class="too-many-modal__content">
      <button
          type="button"
          class="too-many-modal__button"
          aria-label="Close this message"
          data-a11y-dialog-hide
          @click="closeDialog()"
      >
        &times;
      </button>
      <h1 class="sr-only" :id="`${id}-heading`">Too many {{title}} selected</h1>
      <p class="too-many-modal__text">
        {{toConfirmMore}} {{ errorString }}, {{pleaseCall}}
        <a class="underline-link" href="tel:+18884446664">
          1-888-444-OMNI (6664)
        </a>
        {{omniAssist}}.
      </p>
    </div>
  </div>
</template>