<script>
// import IbeCore from "../ibe-core";
import {useMainStore} from '@/stores/mainStore';
import ChildAgesPart from '@/components/parts/ChildAgesPart.vue';
import TooManyModal from '@/components/parts/TooManyModal.vue';

/**
 * @typedef PersonType
 * @type {{add(*), amount(*), min(): number, max(): number, name: string, remove(*)}}
 */
const PersonType = {
  name: '',
  add(room) {
  },
  remove(room) {
  },
  amount(room) {
  },
  min() {
    return 0;
  },
  max() {
    return 4;
  },
};
export default {
  props: {
    isDropdownMode: Boolean,
    room: String,
    rooms: String,
    excludedHotelIdsForConnectingRoomsOption: Array,
    interconnectingText: String,
    guaranteedConnectingRooms: String,
    interconnectingExtraOne: String,
    interconnectingExtraTwo: String,
    adult: String,
    adults: String,
    childDefinition: String,
    child: String,
    children: String,
    guest: String,
    guests: String,
    howManyRooms: String,
    guestChildrenAges: String,
    theAge: String,
    toConfirmMore: String,
    pleaseCall: String,
    omniAssist: String,
    associateBookingsMaxRoomsString: {type: String, default: ""},
    associateBookingsMaxAdultsString: String
  },
  components: {ChildAgesPart, TooManyModal},
  data() {
    return {
        showAdditionalTextInterconnecting: false,
      pickerId: 'guests-picker',
      ibeCore: this.$parent.$parent,
      peopleTypesArray: [
        Object.create(PersonType, {
          name: {value: 'adults'},
          display: {value: this.adults},
          add: {
            value: (index) => (this.ibeCore.addAdult(null, index)),
          },
          remove: {
            value: (index) => (this.ibeCore.subAdult(null, index)),
          },
          amount: {
            value: (index) => (this.ibeCore.selectedAdults[index]),
          },
          min: {
            value: (index) => (1),
          },
          max: {
            value: (index) => (this.associateBookingsMaxAdultsString === "" ? this.ibeCore.maxAdults : parseInt(this.associateBookingsMaxAdultsString)),
          },
          errorMessage: {
            value: (index) => (`${this.ibeCore.selectedAdults[index]} ${this.adults}`),
          },
          errorTitle: {
            value: this.adults,
          },
        }),
        Object.create(PersonType, {
          removedAgesArray: {value: []},
          name: {value: 'children'},
          display: {value: this.children},
          add: {
            value: (index) => (this.ibeCore.addChild(null, index)),
          },
          remove: {
            value: (index) => (this.ibeCore.subChild(null, index)),
          },
          amount: {
            value: (index) => (this.ibeCore.selectedChildren[index]),
          },
          max: {
            value: (index) => {
              return this.ibeCore.maxChildren[index];
            },
          },
          errorMessage: {
            value: (index) => {
              const childString = this.ibeCore.selectedChildren[index] === 1 ? this.child : this.children;
              const adultString = this.ibeCore.selectedAdults[index] === 1 ? this.adult : this.adults;
              return `${this.ibeCore.selectedAdults[index]} ${adultString} and ${this.ibeCore.selectedChildren[index]} ${childString}`;
            },
          },
          errorTitle: {
            value: this.children,
          },
        }),
      ],
      maxRoomsInt: this.associateBookingsMaxRoomsString === '' ?
          ((window.searchWidget && window.searchWidget.rooms) ? window.searchWidget.rooms : 3) :
          parseInt(this.associateBookingsMaxRoomsString),
      errorMessage: '',
      errorTitle: '',
      topOffset: '',
      filterBedroom: [""],
      filterView: [""]
    };
  },
  methods: {


      toggleTextForInterconnectingCheckbox() {
          this.showAdditionalTextInterconnecting = !this.showAdditionalTextInterconnecting;
      },

    /**
     * @param {string} ageStr
     * @return {boolean}
     */
    isChildAgeValid(ageStr){
      return ageStr.trim() !== '' && ageStr >= 0 && ageStr <= 17
    },
    /**
     *
     * @param {HTMLButtonElement} el
     */
    showChildAgesError(el) {
      el.classList.add('user-input');
      el.setCustomValidity(!this.isChildAgeValid(el.value) ? "Children's age is required" : '');

      const errorElId = el.dataset.errormessage;
      const errorEl = document.getElementById(errorElId);
      const errorElSet = new Set(errorEl.dataset.errorList.split(","));
      errorElSet.delete("");

      if (el.validationMessage !== '') {
        errorElSet.add(el.id);
        el.setAttribute('aria-describedby', errorElId);
        el.setAttribute('aria-invalid', 'true');
      }
      else {
        errorElSet.delete(el.id);
        el.removeAttribute('aria-describedby');
        el.setAttribute('aria-invalid', 'false');
      }

      errorEl.dataset.errorList = Array.from(errorElSet).join(",");
      if(errorElSet.size === 0){
        errorEl.textContent = '';
      }else{
        errorEl.textContent = 'Children\'s age is required';
      }
    },
    /**
     *
     * @param {PersonType} personType
     * @param {number}index
     */
    addPeopleButtonClicked(personType, index) {
      if (personType.amount(index) < personType.max(index)) {
        personType.add(index);
      }
      else {
        this.topOffset = `${200 + index * 120}px`;
        this.errorMessage = personType.errorMessage(index);
        this.errorTitle = personType.errorTitle;
      }
    },
    /**
     *
     * @param {Event} event
     */
    addRoomButtonClicked(event) {
      if (this.ibeCore.selectedRooms + 1 > this.maxRoomsInt) {
        this.errorMessage = `${this.maxRoomsInt} ${this.rooms}`;
        this.errorTitle = this.rooms;
        this.topOffset = `100px`;
      }
      else {
        this.ibeCore.addRooms(event);
        this.$emit('rooms-changed', this.ibeCore.selectedRooms);
        this.filterBedroom[this.ibeCore.selectedRooms - 1] = this.filterBedroom[this.ibeCore.selectedRooms - 1] ?? "";
        this.filterView[this.ibeCore.selectedRooms - 1] = this.filterView[this.ibeCore.selectedRooms - 1] ?? "";
      }
    },
  },
  computed: {
    ...Pinia.mapStores(useMainStore),
    totalAdults() {
      let total = 0;
      for (let i = 1; i <= this.ibeCore.selectedRooms; ++i) {
        total += this.ibeCore.selectedAdults[i];
      }
      return total;
    },
    showConnectingDiv() {
          return this.ibeCore.selectedRooms == 2
          && !this.associateBookingsMaxRoomsString
          && this.isDropdownMode
          && (this.ibeCore.selectedHotel === undefined || !this.excludedHotelIdsForConnectingRoomsOption.includes(this.ibeCore.selectedHotel));
    },
    totalChildren() {
      let total = 0;
      for (let i = 1; i <= this.ibeCore.selectedRooms; ++i) {
        total += this.ibeCore.selectedChildren[i];
      }
      return total;
    },
    roomString() {
      return this.ibeCore.selectedRooms === 1 ? this.room : this.rooms;
    },
    adultString() {
      return this.totalAdults === 1 ? this.adult : this.adults;
    },
    childString() {
      return this.totalChildren === 1 ? this.child : this.children;
    },
    guestString() {
      return this.ibeCore.getTotalPeople() === 1 ? this.guest : this.guests;
    },
    currentlySelectedString() {
      return `${this.ibeCore.selectedRooms} ${this.roomString}, ${this.totalAdults} ${this.adultString}, ${this.totalChildren} ${this.childString}`;
    },
    guestsAndRoomsString() {
      return `${this.ibeCore.selectedRooms} ${this.roomString}, ${this.ibeCore.getTotalPeople()} ${this.guestString}`;
    },
  },
  emits: ['guest-string'],
  created() {
    this.ibeCore.selectedRooms = this.mainStore.getFormData(this.mainStore.fieldNames.rooms,
        this.ibeCore.selectedRooms);
    this.ibeCore.selectedAdults = Object.assign({}, this.ibeCore.selectedAdults,
        this.mainStore.getFormData(this.mainStore.fieldNames.adults, {1: 2}));
    this.ibeCore.selectedChildren = Object.assign({}, this.ibeCore.selectedChildren,
        this.mainStore.getFormData(this.mainStore.fieldNames.children, {}));
    this.ibeCore.availabilitySearch.childAges = Object.assign({}, this.ibeCore.availabilitySearch.childAges,
        this.mainStore.getFormData(this.mainStore.fieldNames.childAge, {}));
  },
  mounted() {
    this.filterView = Object.assign( [] , this.filterView, this.mainStore.getFormData(this.mainStore.fieldNames.filterView, this.filterView));
    this.filterBedroom = Object.assign( [] ,this.filterBedroom, this.mainStore.getFormData(this.mainStore.fieldNames.filterBedroom, this.filterBedroom));

    for (let i = this.ibeCore.selectedRooms + 1; i <= this.ibeCore.rooms; i++) {
      if (2 <= this.ibeCore.maxAdults) {
        this.ibeCore.selectedAdults[i] = 2;
      }
    }


    this.$watch(
        () => this.mainStore.selectedHotel,
        (hotel) => {
          this.ibeCore.selectedHotel = hotel.id;
        },
        {immediate: true},
    );

    this.$watch(
        () => this.ibeCore.selectedRooms,
        () => {
          this.ibeCore.updateEachRoom();
        },
        {immediate: true},
    );

    this.$watch(
        () => this.guestsAndRoomsString,
        (v) => {
          window.bookingWidget.data.guests = v;
          this.$emit('guest-string', v);
          window.dispatchEvent(new CustomEvent('guestsupdated', {detail: {guests: v}}));
        },
        {immediate: true},
    );

    this.$emit("date-errors-callback", () => {
      return (this.ibeCore.selectedRooms >= 1 && this.ibeCore.selectedRooms <= this.maxRoomsInt) && (this.ibeCore.selectedAdults);
    });
  },
};
</script>
<template>
  <li
      ref="guestsPickerElem"
      class="picker picker--guests"
      :id="pickerId"
  >
    <div class="picker__title">
      <button class="picker__title__button picker__title--guests-icon"
              type="button"
              aria-expanded="false"
              :aria-controls="pickerId+'-dropdown'"
              @click="mainStore.togglePicker($refs.guestsPickerElem);"
              :data-picker-id="pickerId">
        <span class="picker__title__text" v-if="isDropdownMode">
          {{ ibeCore.selectedRooms }} {{ roomString }},
          {{ ibeCore.getTotalPeople() }} {{ guestString }}
        </span>
        <span class="picker__title__text picker__title__text--guests" v-else>
          {{ currentlySelectedString }}
        </span>
      </button>
    </div>
    <div :id="pickerId+'-dropdown'" class="picker__dropdown picker__dropdown--guests-picker">
      <p v-if="isDropdownMode" class="current-rooms-and-people">
        {{ currentlySelectedString }}
      </p>
      <TooManyModal
          :id="'error-modal-guests'"
          :hidden="errorMessage === ''"
          :error-string="errorMessage"
          :title="errorTitle"
          :toConfirmMore="toConfirmMore"
          :pleaseCall="pleaseCall"
          :omniAssist="omniAssist"
          :top-offset="topOffset"
          @closed="errorMessage = ''; errorTitle = '';"
      ></TooManyModal>
      <fieldset v-show="!mainStore.isModifyingBooking" class="add-rooms"
        aria-labelledby="room-picker-legend">
        <div id="room-picker-legend"
                class="add-rooms__title">
          <template v-if="mainStore.selectedHotel.roomName">
            How many {{mainStore.selectedHotel.roomName}}s
          </template>
          <template v-else>
            {{ howManyRooms }}
          </template>
        </div>
        <button class="picker__close-button close-dropdown" type="button" aria-label="close guests picker"
                @click="mainStore.closePickerAndFocusPickerButton"></button>
        <button
            id="remove-room"
            aria-controls="number-of-rooms"
            aria-label="Remove room"
            type="button"
            class="add-rooms__button"
            @click="ibeCore.subRooms($event)"
            :disabled="ibeCore.selectedRooms <= 1"
        >
          -
        </button>
        <input
            id="number-of-rooms"
            aria-label="Number of rooms"
            :value="ibeCore.selectedRooms"
            type="text"
            class="add-rooms__input"
            readonly
            :name="mainStore.fieldNames.rooms"
        />
        <button
            id="add-room"
            aria-controls="number-of-rooms"
            aria-label="Add room"
            type="button"
            class="add-rooms__button"
            @click="addRoomButtonClicked($event)"
            :class="{'add-rooms__button--disabled': ibeCore.selectedRooms >= maxRoomsInt}"
        >
          +
        </button>

      </fieldset>
      <span class="modifying-booking-text" v-if="mainStore.isModifyingBooking">
        {{ mainStore.text.modifyingBooking }} {{ mainStore.selectedHotel.name }}
      </span>
      <div class="booking-widget-room-list" :class="{ 'scrollYRoomsGreaterThree' : this.ibeCore.selectedRooms >= 3 }">
        <div class="room" v-for="index in ibeCore.selectedRooms" :key="index">
          <h3 class="room__title">{{ mainStore.selectedHotel.roomName ?? room }} {{ index }}</h3>
          <fieldset
              class="add-people"
              :aria-labelledby="`room-${index}-legend-${personType.name}`"
              v-for="(personType, pindex) in peopleTypesArray"
              :key="pindex"
          >
            <div :id="`room-${index}-legend-${personType.name}`"
                    class="add-people__title">
              {{ personType.display }}
            </div>
            <button type="button"
                    class="show-widget__question-mark show-widget__question-mark--guest"
                    :aria-controls="`explain-child-ages-${index}-${pindex}`"
                    aria-label="Explain child ages"
                    aria-expanded="false"
            >
              i
            </button>
            <ChildAgesPart :id="`explain-child-ages-${index}-${pindex}`"
                           :childDefinition="childDefinition"
                           :class="personType.name" class="room__child-ages-info">
            </ChildAgesPart>
            <button
                :id="`room-${index}-remove-${personType.name}`"
                :aria-controls="`room-${index}-${personType.name}`"
                :aria-label="`Remove ${personType.name}`"
                type="button"
                class="add-people__button"
                @click="personType.remove(index)"
                :disabled="personType.amount(index) <= personType.min(index)"
            >
              -
            </button>
            <label :for="`room-${index}-${personType.name}`" class="sr-only">{{ personType.name }} in room
              {{ index }}</label>
            <input
                :id="`room-${index}-${personType.name}`"
                type="text"
                class="add-people__input"
                :value="personType.amount(index)"
                :name="personType.name + '[' + parseInt(index) + ']'"
                readonly
            />
            <button
                :id="`room-${index}-add-${personType.name}`"
                :aria-controls="`room-${index}-${personType.name}`"
                :aria-label="`Add ${personType.name}`"
                type="button"
                class="add-people__button"
                @click="addPeopleButtonClicked(personType, index)"
                :class="{'add-people__button--disabled' : personType.amount(index) >= personType.max(index)}"
            >
              +
            </button>
            <fieldset
                class="add-child-ages"
                :aria-labelledby="`child-ages-legend-${index}-${pindex + 1}`"
                v-if="personType.name === 'children' && ibeCore.showChildAgesForRoom(index)"
            >
              <div :id="`child-ages-legend-${index}-${pindex + 1}`"
                   class="add-child-ages__title">
                {{ guestChildrenAges }}
              </div>
              <button type="button"
                      class="show-widget__question-mark show-widget__question-mark--guest"
                      :aria-controls="`explain-child-ages-${index}-${pindex + 1}`"
                      aria-label="Explain child ages"
                      aria-expanded="false"
              >
                i
              </button>
              <ChildAgesPart :id="`explain-child-ages-${index}-${pindex + 1}`"
                             :childDefinition="childDefinition"
                             class="children-ages">
              </ChildAgesPart>
              <div class="add-child-ages__selects">
                <template v-for="childIndex in personType.amount(index)" :key="childIndex">
                  <label
                      :for="`select-child-age-${index}-${childIndex}`"
                      class="sr-only"
                  >
                    Add age for child {{ childIndex }} in room {{ index }}
                  </label>
                  <select
                      :id="`select-child-age-${index}-${childIndex}`"
                      v-model="ibeCore.availabilitySearch.childAges[index][childIndex]"
                      class="add-child-ages__select custom-select"
                      required
                      :name="`${mainStore.fieldNames.childAge}[${parseInt(index)}][${parseInt(childIndex)}]`"
                      :data-errormessage="'child-ages-error-'+index"
                      @invalid.prevent="showChildAgesError($event.target);"
                      @change="showChildAgesError($event.target)"
                  >
                    <option value="" selected>
                      {{ theAge }}
                    </option>
                    <option v-for="(n, i) in 18" :key="n">
                      {{ i }}
                    </option>
                  </select>
                </template>
              </div>
              <strong
                  :id="`child-ages-error-${index}`"
                  aria-live="polite"
                  class="error"
                  data-error-list=""
              ></strong>
            </fieldset>
          </fieldset>
          <fieldset v-if="mainStore.selectedHotel.hasRoomFilters" class="additional-filters">
            <legend class="sr-only">Additional filters</legend>
            <label :for="`widget-filter-bedrooms-${index}`">No. of Bedrooms (Optional)</label>
            <select v-model="filterBedroom[index - 1]" :id="`widget-filter-bedrooms-${index}`" :name="`filterBedroom[${index}]`" class="additional-filters__select custom-select"><option value="">SELECT</option><option v-for="v in ['1','2','3','4+']">{{v}}</option></select>
            <label :for="`widget-filter-views-${index}`">Select View (Optional)</label>
            <select v-model="filterView[index - 1]" :id="`widget-filter-views-${index}`" :name="`filterView[${index}]`" class="additional-filters__select custom-select"><option value="">SELECT</option><option :id="`widget-filter-views-${index}`" v-for="view in mainStore.selectedHotel.views">{{view}}</option></select>
          </fieldset>
        </div>
      </div>
        <template>
            <input type="hidden" name="interconnecting_rooms_checkbox" v-bind:value="mainStore.interconnectingRoomsChecked?1:0"/>
            <div v-if="showConnectingDiv" class="interconnecting-checkbox-text">
               <div class="interconnecting-texts-wrapper  mb-1">
                   <label class="d-flex align-items-start mb-0" for="interconnectingRoomsInput">
                       <div class="form-check">
                           <input v-model="mainStore.interconnectingRoomsChecked" class="checkbox-input__input form-control" type="checkbox" value="1" name="interconnecting_rooms_checkbox" id="interconnectingRoomsInput"  >
                           <span class="custom-check"></span>

                       </div>
                    <div class="interconnecting-texts">
                       <span> {{ interconnectingText }}</span>
                       <span>{{ guaranteedConnectingRooms }}</span>
                    </div>

                   </label>
                   <button type="button" tabindex="0"
                      class="destination-charge__popover-btn"
                      aria-controls="connecting-room-text-popup"
                      :aria-expanded="showAdditionalTextInterconnecting.toString()"
                      @click="toggleTextForInterconnectingCheckbox">
                       <img  :src="'/images/icons/icon-info-connecting-rooms.svg'" alt="close">
                   </button>
               </div>
                <div id="connecting-room-text-popup" class="interconnecting-text-info-wrapper  mt-2 mb-2" v-if="showAdditionalTextInterconnecting">
                    <button type="button" @click.stop="toggleTextForInterconnectingCheckbox"><img  :src="'/images/icons/x-connecting.svg'" alt="close"></button>
                   <div class="interconnecting-text-info ml-3 ">
                     <span class="mb-2">{{ interconnectingExtraOne }}</span>
                     <span>{{ interconnectingExtraTwo }}</span>
                   </div>
                </div>

            </div>
        </template>
    </div>

  </li>
</template>
