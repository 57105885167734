<script>
import {useMainStore} from "@/stores/mainStore";
import {userInput} from "../toggle-validation-classes";

let RadioButtonObject = {
  pickerTitle() {
    return this.output;
  }
};
const RadioButtonTextObject = Object.create(RadioButtonObject);
RadioButtonTextObject.focus = function () {
  this.isFocused.value = true;
};
RadioButtonTextObject.blur = function () {
  this.isFocused.value = false;
};
RadioButtonTextObject.isSelected = function () {};
export default {
  props: {
      isDropdownMode: Boolean,
      freeNightsLink: String,
      specialRates: String,
      allRates: String,
      seniorDiscount: String,
      governmentMilitary: String,
      promoCode: String,
      corporateCode: String,
      groupCode: String,
      selectGuestMember: String,
      redeemFreeNights: String,
      useFreeNights: String,
      travelAgentNumber: String
  },
  data() {
    return {
      pickerId: "special-rates-picker",
      defaultRateSelectedString: this.specialRates,
      travelAgentValue: "",
      travelCodeFocused: "",
      radioObjectsArray: [
        Object.create(RadioButtonObject, {
          value: {value: "All"},
          output: {value: this.allRates},
          pickerTitle: {value: () => this.defaultRateSelectedString},
          id: {value: "all-rates"},
          checked: {value: true},
          hidden: {value: 0},
          disabled: {value: 0},
        }),
        Object.create(RadioButtonObject, {
          value: {value: "ASC"},
          output: {value: "AAA"},
          id: {value: "aaa"},
        }),
        Object.create(RadioButtonObject, {
          value: {value: "SNR"},
          output: {value: this.seniorDiscount},
          id: {value: "senior"},
        }),
        Object.create(RadioButtonObject, {
          value: {value: "GOV"},
          output: {value: this.governmentMilitary},
          id: {value: "government"},
        })
      ],
      radioTextObjectsArray: [
        Object.create(RadioButtonTextObject, {
          value: {value: "promo"},
          output: {value: this.promoCode},
          id: {value: "promo"},
          isFocused: {value: window.Vue.ref(false), writable: true},
          hidden: {value: 0},
          disabled: {value: 0},
          code: {value: "", writable: true},
        }),
        Object.create(RadioButtonTextObject, {
          value: {value: "corp"},
          output: {value: this.corporateCode},
          id: {value: "corporate"},
          isFocused: {value: window.Vue.ref(false), writable: true},
          code: {value: "", writable: true},
        }),
        Object.create(RadioButtonTextObject, {
          value: {value: "block"},
          output: {value: this.groupCode},
          id: {value: "group-code"},
          isFocused: {value: window.Vue.ref(false), writable: true},
          code: {value: "", writable: true},
        }),
      ],
      selectedRadioButton: "",
      previousSelectedRadioButton: {
        output: "",
        value: "",
        code: null,
        hasChanged: true,
      },
      specialRateSelectedString: "",
      selectedRadioTextObject: ""
    }
  },
  methods: {
    userInput,
    radioChange(radioButtonObject) {
      this.specialRateSelectedString = radioButtonObject.pickerTitle();
      this.previousSelectedRadioButton.hasChanged = true;
    },
    focusedLabel(radioButtonObject) {
      radioButtonObject.focus();
      this.selectedRadioButton = radioButtonObject.value;
      this.radioChange(radioButtonObject);
    },
    blurLabel(currentValueString, radioButtonObject) {
      if (currentValueString.trim() === "") {
        radioButtonObject.blur();
      }
    },
    initialFocus(event, selectorString) {
      const focusableElement = this.$refs.ratesFieldsetElement.querySelector(selectorString);
      if (focusableElement !== null) {
        event.preventDefault();
        focusableElement.focus();
        return true;
      }
      return false;
    },
    initialFocusFromTab(event) {
      if (event.currentTarget.getAttribute("aria-expanded") === true) {
        this.initialFocus(event, "input:checked");
      }
    },
    initialFocusFromShiftTab(event) {
      if (this.initialFocus(event, "input[type='text']:not([tabindex='-1'])") === false) {
        this.initialFocus(event, "input:checked");
      }
    },
    focusInput(event, increment) {
      const focusableElementsArray = Array.from(this.$refs.ratesFieldsetElement.querySelectorAll("input:not([disabled]):not([tabindex='-1']):not([type='hidden'])"));
      const currentIndex = focusableElementsArray.indexOf(event.currentTarget);
      if (focusableElementsArray[currentIndex + increment] !== undefined) {
        event.preventDefault();
        focusableElementsArray[currentIndex + increment].focus();
      }
    },
    focusNextInput(event) {
      this.focusInput(event, 1);
    },
    focusPreviousInput(event) {
      this.focusInput(event, -1);
    },
    toggleInfoBox() {
      this.$refs.explainSelectGuestsElement.hidden = !this.$refs.explainSelectGuestsElement.hidden;
      document.querySelectorAll("[aria-controls='bw-explain-select-guests']").forEach(
          (el) => el.setAttribute('aria-expanded', !this.$refs.explainSelectGuestsElement.hidden)
      );
    }
  },
  computed: {
    ...Pinia.mapStores(useMainStore),
    inputName() {
        if(this.radioObjectsArray[0].value === this.selectedRadioButton){
          return "";
        }else if(this.radioObjectsArray.some((el) => el.value === this.selectedRadioButton)) {
          return this.mainStore.fieldNames.rates;
        } else {
          return this.mainStore.fieldNames.codeType;
        }
      },
  },
  mounted() {
    const allRadioButtonsArray = this.radioObjectsArray
        .concat(this.radioTextObjectsArray);
    const requestedRadioButton = this.mainStore.getFormData(this.mainStore.fieldNames.rates,
        this.mainStore.getFormData(this.mainStore.fieldNames.codeType, this.radioObjectsArray[0].value));

    this.selectedRadioButton = this.radioObjectsArray[0].value;

    const selectedCodeStr = this.mainStore.getFormData(this.mainStore.fieldNames.code, "").trim();
    this.selectedRadioTextObject = this.radioTextObjectsArray.find((el) => {
      return el.value === this.mainStore.formData.codeType;
    });
    const validRadioAndTextSelectedBool = this.selectedRadioTextObject && selectedCodeStr !== "" && selectedCodeStr.toLowerCase() !== "none";

    allRadioButtonsArray
        .forEach((el) => {
          if (el.hidden === undefined) {
            this.$set(el, 'hidden', window.Vue.toRef(this.mainStore, "flexibleDates"));
            this.$set(el, 'disabled', window.Vue.toRef(this.mainStore, "flexibleDates"));
          }

          if (el.isSelected !== undefined) {
            this.$set(el, 'isSelected', () => el.value === this.selectedRadioButton);
            this.$watch(
                () => el.value === this.selectedRadioButton,
                (isSelected) => {
                  if (!isSelected) {
                    el.code = "";
                    el.blur();
                  }
                });
          }

          if (el.value === requestedRadioButton
              && (validRadioAndTextSelectedBool || this.selectedRadioTextObject === undefined)) {
            this.selectedRadioButton = requestedRadioButton;
          }
        });

    if (validRadioAndTextSelectedBool) {
      this.selectedRadioTextObject.code = selectedCodeStr;
      this.selectedRadioTextObject.focus();
    }

    this.travelAgentValue = this.mainStore.getFormData(this.mainStore.fieldNames.iataNumber, "");
    this.travelCodeFocused = this.travelAgentValue !== "" ? "focused" : "";

    this.specialRateSelectedString = (
        allRadioButtonsArray.find((el) => el.value === this.selectedRadioButton) ?? this.radioObjectsArray[0]).pickerTitle();

    this.mainStore.$subscribe((mutation, state) => {
      if (state.flexibleDates === "1") {
        this.previousSelectedRadioButton.output = this.specialRateSelectedString;
        this.previousSelectedRadioButton.value = this.selectedRadioButton;
        const codeStr = allRadioButtonsArray.find((el) => el.value === this.selectedRadioButton).code;
        if(codeStr){
          this.previousSelectedRadioButton.code = codeStr;
        }else{
          this.previousSelectedRadioButton.code = null
        }
        if (!allRadioButtonsArray.some(el => (el.hidden === 0) && (el.output === this.specialRateSelectedString))) {
          this.selectedRadioButton = this.radioObjectsArray[0].value;
          this.radioChange(this.radioObjectsArray[0]);
        }
        this.previousSelectedRadioButton.hasChanged = false;
      } else {
        if (!this.previousSelectedRadioButton.hasChanged) {
          this.specialRateSelectedString = this.previousSelectedRadioButton.output;
          this.selectedRadioButton = this.previousSelectedRadioButton.value;
          if(this.previousSelectedRadioButton.code !== null){
            const radioTextButtonObj = allRadioButtonsArray.find((el) => el.value === this.selectedRadioButton);
            radioTextButtonObj.code = this.previousSelectedRadioButton.code
            radioTextButtonObj.focus();
          }
        }
      }
    });
  },
};
</script>
<template>
  <li
      ref="specialRatesPickerElem"
      :id="pickerId"
       class="picker picker--special-rates">
    <div class="picker__title">
      <button class="picker__title__button"
            type="button"
            aria-expanded="false"
            :aria-controls="pickerId+'-dropdown'"
            @click="mainStore.togglePicker($refs.specialRatesPickerElem);"
            :data-picker-id="pickerId">
        <span class="picker__title__text text-capitalize"><span class="special-rates-icon" aria-hidden="true"></span>{{ specialRateSelectedString }}</span>
      </button>
    </div>
    <div :id="pickerId+'-dropdown'" class="picker__dropdown">
      <fieldset ref="ratesFieldsetElement" class="rates-selection">
        <legend class="sr-only">Select Rate</legend>
        <button class="picker__close-button close-dropdown" type="button" aria-label="close rates picker" @click="mainStore.closePickerAndFocusPickerButton"></button>
        <p class="required-field-text">* indicates a required field</p>
        <input type="hidden" :value="selectedRadioButton" :name="inputName" >
        <div
            class="radio-input"
            v-for="(radioButtonObject, index) in radioObjectsArray"
            :key="radioButtonObject.id"
            :hidden="radioButtonObject.hidden === '1'"
        >
          <input
              :id="`radio-${radioButtonObject.id}`"
              class="radio-input__input form-control"
              type="radio"
              name="rateType"
              :value="radioButtonObject.value"
              :checked="radioButtonObject.checked"
              :disabled="radioButtonObject.disabled === '1'"
              v-model="selectedRadioButton"
              @change="radioChange(radioButtonObject)"
              @keydown.tab.exact="focusNextInput($event)"
              @keydown.tab.shift.exact="focusPreviousInput($event)"
          />
          <label
              :for="`radio-${radioButtonObject.id}`"
              class="radio-input__label text-capitalize"
          >
            <span class="custom-radio"></span>
            {{ radioButtonObject.output }}
          </label>
        </div>
        <div
            class="radio-text-input"
            v-for="(radioButtonObject, index) in radioTextObjectsArray"
            :key="radioButtonObject.id"
            :hidden="radioButtonObject.hidden === '1'"
        >
          <label
              :for="`radio-${radioButtonObject.id}`"
              class="sr-only"
          >
            {{ radioButtonObject.output }}
          </label>
          <input
              :id="`radio-${radioButtonObject.id}`"
              class="radio-text-input__radio form-control"
              type="radio"
              name="rateType"
              :value="radioButtonObject.value"
              :disabled="radioButtonObject.disabled === '1'"
              v-model="selectedRadioButton"
              @change="radioChange(radioButtonObject)"
              @keydown.tab.exact="focusNextInput($event)"
              @keydown.tab.shift.exact="focusPreviousInput($event)"
          />
          <label
              :for="`radio-text-${radioButtonObject.id}`"
              class="radio-text-input__label"
          >
            <span class="custom-radio"></span>
            <span
                class="floating-label"
                :class="radioButtonObject.isFocused.value ? 'focused' : ''"
            ><span class="sr-only">Input </span>{{ radioButtonObject.output }} {{ radioButtonObject.isSelected() ? "*" : ""}}</span>
          </label>
          <input
              :id="`radio-text-${radioButtonObject.id}`"
              type="text"
              class="radio-text-input__text form-control"
              v-model="radioButtonObject.code"
              :name="radioButtonObject.isSelected() ? mainStore.fieldNames.code: ''"
              :required="radioButtonObject.isSelected()"
              :aria-errormessage="`radio-${radioButtonObject.id}-error`"
              :data-errormessage="`Please input a ${radioButtonObject.output}`"
              :tabindex="radioButtonObject.isSelected()? 0: -1"
              @focus="focusedLabel(radioButtonObject)"
              @blur="blurLabel($event.target.value, radioButtonObject)"
              @invalid.prevent="userInput($event.target);"
              @change="userInput($event.target)"
              @keydown.tab.exact="focusNextInput($event)"
              @keydown.tab.shift.exact="focusPreviousInput($event)"
          />
          <strong
              v-if="radioButtonObject.value === selectedRadioButton"
              aria-live="polite"
              :id="`radio-${radioButtonObject.id}-error`"
              class="error"
          ></strong>
        </div>
      </fieldset>
      <input v-if="mainStore.formData.token !== undefined" type="hidden" name="token"
             :value="mainStore.formData.token"/>
      <input v-if="mainStore.formData.customfieldset1 !== undefined" type="hidden" name="customfieldset1"
             :value="mainStore.formData.customfieldset1"/>
      <input v-if="mainStore.formData.customfieldset2 !== undefined" type="hidden" name="customfieldset2"
             :value="mainStore.formData.customfieldset2"/>
      <input v-if="mainStore.formData.customfieldset3 !== undefined" type="hidden" name="customfieldset3"
             :value="mainStore.formData.customfieldset3"/>
      <section v-if="!mainStore.hideSelectGuest" class="special-guest" :hidden="mainStore.flexibleDates === '1'">
        <h3 class="special-guest__title">
          {{selectGuestMember}}
        </h3>
        <button
            ref="selectGuestsButtonElement"
            class="show-widget__question-mark show-widget__question-mark--rates"
            type="button"
            aria-controls="bw-explain-select-guests"
            aria-label="Explain select guests"
            @click="toggleInfoBox"
            aria-expanded="false"
        >
          ?
        </button>
        <p ref="explainSelectGuestsElement"
           id="bw-explain-select-guests"
           class="show-widget__details show-widget__text special-guest__text" hidden>
          <button class="show-widget__x"
                  type="button"
                  @click="toggleInfoBox(); $refs.selectGuestsButtonElement.focus();"
          >
            X
          </button>
          {{redeemFreeNights}}
        </p>
        <a :href="freeNightsLink" class="special-guest__link">
          {{useFreeNights}}
        </a>
      </section>
      <div class="text-input">
        <input
            id="travel-agent-number"
            type="text"
            class="text-input__input form-control"
            :name="mainStore.fieldNames.iataNumber"
            v-model="travelAgentValue"
            @focus="travelCodeFocused = 'focused'"
            @blur="travelCodeFocused = $event.target.value !== '' ? 'focused' : ''"
        />
        <label
            for="travel-agent-number"
            class="text-input__label floating-label"
            :class="travelCodeFocused"
        >
          {{travelAgentNumber}}
        </label>
      </div>
    </div>
  </li>
</template>
